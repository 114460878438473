import { AbilityBuilder } from '@casl/ability';

export default function defineRulesFor(data) {
    const { can, rules } = new AbilityBuilder();
   for(let row of data) {
       let rowArr = row.split('_'),
           action = rowArr.shift(),
           model = rowArr.join('_');
        can(action,model)    
    }  
    return rules
}   