<template>
  <v-col cols="12" md="12" lg="6" xl="6" class="d-none d-md-flex align-center justify-center"
         :style="'background-color: ' + $store.getters.primaryColor + ' !important; height:100%'">
    <v-container>
      <div :style="' width: 50%;  height: 100%;  position: absolute; opacity: 0.1; top: 0;  left: 0; background: url(' +
          getBackground + ') no-repeat center center; background-size: cover; transform: scale(1); '"/>
      <v-row v-if="info.name" justify="center">
        <v-col class="company" cols="10">
          <div style="text-align: center">
            <img v-if="info.logo" :src="getLogo" style="height: 80px; width: auto; position: relative; margin-bottom: 15px;"/>
            <br v-if="info.logo" />
            <h2 v-if="info.name" style="position: relative">{{ info.name }}</h2>
            <p v-if="info.welcome" v-html="info.welcome" class="mt-4" style="font-family: 'Poppins' !important; line-height: unset !important;"></p>
            <template v-if="info.website">
              <v-btn
                  small
                  :href="info.website"
                  target="_blank"
                  class="text-capitalize mt-5"
                  x-large
                  outlined
                  color="white">
                Over ons
              </v-btn>
            </template>
          </div>
        </v-col>
      </v-row>
      <a href="https://pernexus.org" target="_blank"><img class="branding" src="/assets/images/promo.png"/></a>
    </v-container>
  </v-col>
</template>

<script>
export default {
  created() {
    this.getInfo();
  },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    getInfo() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/info")
          .then((response) => {
            this.info = response.data;
            this.$store.dispatch("setColors", {
              primary_color: response.data.primary_color,
              secondary_color: response.data.secondary_color,
            });

            this.$vuetify.theme.themes.light.primary =
                response.data.primary_color;

            this.$vuetify.theme.themes.light.secondary =
                response.data.secondary_color;

            // const favicon = document.getElementById("favicon");
            // favicon.href = response.data.logo;

            this.$emit("info", response.data);
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    getLogo() {
      return this.info.logo ? this.info.logo : null;
    },
    getBackground() {
      return this.info.background_image ? this.info.background_image : null;
    },
  },
};
</script>
<style scoped>
.company {
  /* background-color: #fffffff5 !important; */
  z-index: 10;
  padding: 30px;
  border-radius: 3px;
  /* border: 1px solid #eeeeee; */
  color: white !important;
}

@media screen and (min-width: 600px) {
  .branding {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
}
</style>