<template>
  <v-row style="margin: 0">
    <styling @info="setInfo"/>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-btn-toggle v-model="getLanguage" light style="position: fixed; top: 15px; right: 65px; width: 50px">
            <v-btn block small value="nl"> NL </v-btn>
            <v-btn block small value="en"> EN </v-btn>
          </v-btn-toggle>
          <v-row v-if="$vuetify.breakpoint.mdAndDown && logo">
            <v-col class="text-center">
              <img style="width:35%" :src="logo" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <h2 class="font-weight-bold mt-4">
                {{ $store.getters.translate("login") }}
              </h2>
              <h6 v-if="support_email" class="subtitle-1">
                {{ $store.getters.translate("no_account") }}
                <a :href="'mailto:' + support_email" class>{{
                  $store.getters.translate("get_help")
                }}</a>
              </h6>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  class="mt-4"
                  required
                  outlined/>
                <v-text-field
                  v-model="password"
                  :label="$store.getters.translate('password')"
                  required
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  color="primary"/>
                <v-alert v-if="message" dense outlined type="error">
                  {{ message }}
                </v-alert>
                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <div class="mb-4">
                    <a @click="$router.push('/resetpassword')">{{
                      $store.getters.translate("forgot_password")
                    }}</a>
                  </div>
                </div>
                <v-btn
                    @click="submit"
                    :loading="loading"
                    :disabled="!valid"
                    color="primary"
                    block
                    class="mr-4"
                    submit>
                  {{ $store.getters.translate("login") }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import defineRulesFor from "../../ability";
import Styling from "./includable/Styling";

export default {
  name: "FullLogin",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perscriptum",
  components: {
    Styling,
  },
  data: () => ({
    valid: true,
    password: "",
    show1: false,
    passwordRules: [(v) => !!v || "Password is required"],
    email: "",
    loading: false,
    message: null,
    info: {},
    support_email: null,
    logo: null,
  }),
  created() {
    document.title = process.env.VUE_APP_TITLE;
    this.$store.dispatch("refreshTranslations");
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        this.$store.dispatch("login", { email: this.email, password: this.password })
          .then((response) => {
            this.ability.update(defineRulesFor(response.data.assign_permission));
            if (this.getLanguage !== response.data.language) {
              this.$store.dispatch("setLanguage", response.data.language);
            }
            if (this.getNotifyIsOn !== response.data.notify_is_on) {
              this.$store.dispatch("setNotifyIsOn", response.data.notify_is_on);
            }
            this.$store.dispatch("setSidebar", true);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.message = this.$store.getters.translate("invalid_credentials");
          });
      }
    },
    setInfo(data) {
      this.support_email = data.email;
      if (this.$store.getters.releaseVersion !== data.release_version) {
        localStorage.clear();
      }
      this.logo = data.logo;
      this.$store.dispatch("setReleaseVersion", data.release_version);
      this.$store.dispatch("setSMS", data.sms);
    },
  },
  computed: {
    getLanguage: {
      get() {
        return this.$store.getters.getLanguage;
      },
      set(value) {
        this.$store.dispatch("setLanguage", value);
      },
    },
    getNotifyIsOn: {
      get() {
        return this.$store.getters.getNotifyIsOn;
      },
      set(value) {
        this.$store.dispatch("setNotifyIsOn", value);
      },
    },
  },
};
</script>
